@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.text-shadow{
  text-shadow: 2px 2px 5px rgba(0,0,0,0.5);
}
.logo-shadow{
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
}
.divider{
  /* background: #00f9ff; */
  background: #232323;
  margin-top: -25px;
  clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 75%);
}
.divider-2{
  background: #232323;
  margin-top: -25px;
  clip-path: polygon(0 0, 100% 25%, 100% 75%, 0% 100%);

}